<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-card-title>
        <span class="text-capitalize text-h3 font-weight-bold">
          {{ data.customer_name.replace(/_/g, " ") ?? "Un-Assigned" }}
        </span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <!-- <v-container class="d-flex flex-wrap gap-1"> -->
        <v-container>
          <v-row>
            <v-col class="pt-0 mt-0">
              <span class="font-weight-bold">
                {{ isFleet ? $t("test_driver") : $t("test_customers") }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="row in customersData"
              :key="row.id"
              cols="6"
            >
              <v-sheet
                class="CustomerInfo"
                elevation="3"
              >
                <div class="customer-icon">
                  <div class="">
                    <span>
                      <v-badge
                        color="green"
                        overlap
                        bottom
                        dot
                      >
                        <v-icon x-large> mdi-account-tie </v-icon>
                      </v-badge>
                    </span>
                  </div>
                </div>
                <div class="customerDetails">
                  <div>
                    <!-- <v-icon small>{{
                      row.gender == "male"
                        ? "mdi-gender-male"
                        : "mdi-gender-female"
                    }}</v-icon> -->
                    <span class="ml-1 fw-bold">
                      {{ row.name.replace(/_/g, " ") }}
                    </span>
                  </div>
                  <div>
                    <v-icon small>
                      mdi-email
                    </v-icon>
                    <span class="ml-1">
                      {{ row.email }}
                    </span>
                  </div>
                  <div>
                    <v-icon small>
                      mdi-phone
                    </v-icon>
                    <span class="ml-1">
                      {{ row.country_code }}-{{ row.phone_number }}
                    </span>
                  </div>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="red darken-1"
          text
          @click="toggleModal()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    isVisibleDialog: Boolean,

    toggleModal: {
      type: Function,
      default() {
        return void 0;
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      // oemCustomersList: "oemCustomers/customerLists",
      // oemTestCustomersList: "oemCustomers/activeTestCustomersList",
      oemTestCustomersList: "oemCustomers/testCustomersList",
      // isLoadingOemCustomers: "oemCustomers/getIsLoadingOemCustomers",
    }),
    isFleet() {
      return this.authUser.user_nature == "fleet" || this.data.oem_b2b_id
        ? true
        : false;
    },
    customersData() {
      let arr = [];
      this.data.test_customers.forEach((pID) => {
        this.oemTestCustomersList.forEach((row) => {
          if (row.id == pID) {
            arr.push(row);
          }
        });
      });
      return arr;
    },
  },
  async mounted() {
    await this.$store.dispatch("oemCustomers/list");
  },
  methods: {
    setData() {
      this.data.test_customers.forEach((pID) => {
        this.oemTestCustomersList.forEach((row) => {
          if (row.id == pID) {
            this.customersData.push(row);
          }
        });
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.CustomerInfo
  padding: 1rem
  display: flex
  flex: 1
  .customer-icon
    position: relative
    i
      font-size: 5rem
  .customerDetails
    font-size: 13px
    margin-left: 1rem
    div
      display: flex
      line-height: 1.5rem
</style>
